@use '@angular/material' as mat;
@use 'sass:map';
@import '@angular/material/theming';


$primary-palette: mat-palette($mat-indigo);
$accent-palette: mat-palette($mat-pink);
$blue-palette: mat-palette($mat-blue);


$primary-color: mat.get-color-from-palette($primary-palette);
$primary-light-color: mat.get-color-from-palette($blue-palette, 100, 0.3);

$accent-color: mat.get-color-from-palette($accent-palette);

$warn-color: #f44336;

$disabled-light-color: #e2e5ed
